<template>
  <div class="ccc">
    <TopHead></TopHead>
    <logoNav></logoNav>
    <div class="uc_con">

      <div class="uc_title d_f ali_c flex_1">
        <div class="uc_green"></div>
        <div>本月账单信息</div>
      </div>
      <div class="bill_mon d_f ali_c j_s">
        <span>本月剩余应结账单金额：<span class="red_text">￥121212</span></span>
        <span>账单总金额：<span class="bold">￥121212</span></span>
        <span>已结账金额：<span class="bold">￥121212</span></span>
      </div>
    </div>

    <div class="uc_con">
      <div class="uc_title d_f ali_c flex_1">
        <div class="uc_green"></div>
        <div>历史账单信息</div>
      </div>

      <div class="t_l">
        <el-form :inline="true" :model="searchInfo" class="demo-form-inline">
          <el-form-item label="账单出账月份">
            <el-select placeholder="账单出账月份">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="账单状态">
            <el-select placeholder="账单状态">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button @click="searchInfo = {}">重置</el-button>
            <el-button type="primary" @click="search">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        ref="multipleTable"
        :data="list"
        stripe
        :header-cell-style="{'text-align':'center','background-color': '#F4F4F4','font-size': '14px','color': '#333','font-weight': 'bold'}"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column label="账单出账日期" align="center">
          <template #default="scope">{{ scope.row.date }}</template>
        </el-table-column>
        <el-table-column prop="name" label="账单状态" align="center"> </el-table-column>
        <el-table-column prop="address" label="账单总金额" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="address" label=" 结账时间" align="center" show-overflow-tooltip></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
  export default {
    name: "bill",
    data() {
      return {
        searchInfo:{},
        list:[{date:1},{date:2}],
      }
    },
    methods: {
      search(){

      }
    }
  }
</script>

<style scoped lang="scss">

  .el-table{
    border: 1px solid #F4F4F4;
    text-align: center;
  }
  .bill_mon{
    .bold{
      font-size: 20px;
      font-weight: bold;
    }
    .red_text{
      font-size: 42px;
      font-weight: bold;
      color: #FF2F2F;
    }
    margin-bottom: 30px;
    font-size: 20px;
    color: #333333;
  }
</style>
